.infoBarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 0 0 4px 4px;
  margin-top: 1px;
}

.infoItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.label {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 2px;
}

.value {
  color: white;
  font-size: 14px;
  font-weight: 600;
}

.waveInfoBar {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 12px;
  margin-top: 12px;
  width: 100%;
}

.timeInfo {
  margin-bottom: 8px;
  text-align: center;
}

.waveInfoContent {
  display: flex;
  justify-content: space-between;
}

.infoItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
} 
