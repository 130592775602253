@import '../../styles/variables';

.background {
  display: block;
  background-color: $product-secondary;
}

.contentWrapper {
  border-bottom: 1px solid $product-body;
}
