@import '../../styles/variables';
@import '../../styles/mixins';

$editorialCardContentHeight: 90px;
$editorialCardPaddingDesktop: 25px;

.editorialCardContainer {
  height: 100%;
}

.editorialCard {
  padding: 0;
  scroll-snap-align: start;
  width: 100%;
  height: 100%;
  background-color: transparent;
  box-shadow: none;
  @media (min-width: $tablet) {
    background-color: none;
  }
}

.textContainer {
  position: absolute;
  display: flex;
  top: 450px;
  flex-direction: column;
  width: calc(100% - 5px);
  @media (min-width: $tablet) {
    bottom: 32px;
    flex-direction: initial;
    top: auto;
    width: calc(100% - $editorialCardPaddingDesktop * 2);
    flex-wrap: wrap;
  }
}

.chip {
  margin-top: 17px;
  border-radius: 4px;
  background-color: $product-extra-light;
  align-self: baseline;
}

.videoIcon {
  @include sl-video-play-icon;
  z-index: 9;
  top: 20px;
  left: 20px;
  @media (min-width: $tablet) {
    top: 30px;
    left: 30px;
  }
}

.editorialCardImage {
  z-index: 1;
  position: relative;
  width: 100%;
  height: calc(100% - $editorialCardContentHeight);
  border-radius: 8px;
  overflow: hidden;
  @media (min-width: $tablet) {
    height: 100%;
  }
  img {
    transition: transform 600ms;
  }
}

.editorialCardTitle {
  color: white;
  align-self: flex-end;
  width: 100%;
  padding: 10px 0;
  margin-right: 0px;
}

.editorialCardLink {
  align-items: center;
  height: 100%;
  border-radius: 8px;
  background-color: var(--sl-overlay-background);
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: left;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  padding: 0;
  @media (min-width: $tablet) {
    padding-left: $editorialCardPaddingDesktop;
    padding-bottom: $editorialCardPaddingDesktop;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $sl-overlay-background-hover;
    box-shadow: $sl-overlay-box-shadow;

    & + .editorialCardImage img {
      transform: scale(1.1);
    }
  }

  & + .editorialCardImage::after {
    @media (min-width: $tablet) {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.6) 100%);
    }
  }
}
