.skipButton {
  circle {
    stroke: white;
  }

  path {
    fill: black;
  }

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    padding: 0;
  }
}

.skipButton:disabled {
  opacity: 0.3;
}

.time {
  height: 20px;
  background: rgba(23, 23, 23, 0.24);
  border-radius: 20px;
  padding: 0px 8px 2px 8px;
  gap: 5px;
  position: absolute;
  top: 40px;
  left: 20px;
  z-index: 1;
  font-weight: 700;
  font-size: 16px;

  @media (max-width: 768px) {
    top: auto;
    left: auto;
    bottom: 15px;
    right: 15px;
    color: black;
  }
}

.playerContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  align-items: center;

  @media (max-width: 768px) {
    padding: 0;
  }

  .player {
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
  }

  svg {
    color: white;
    fill: white;
    padding: 5px
  }
}

.playerOverlayContainer {
  width: 100%;
  max-width: calc((100vh - 300px) * 16/9);
  height: 100%;
  position: relative;
}

.clipIndicatorContainer {
  width: calc(100% - 40px);
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  position: absolute;
  z-index: 1;
}

.clipIndicator {
  width: 100%;
  height: 4px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.4);
  margin-right: 5px;
}

.activeClip {
  background-color: rgba(255, 255, 255, 1);
}