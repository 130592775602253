@import '../../styles/variables';
@import '../../styles/mixins';

.outlook {
  position: relative;
  min-height: 345px;
  padding-bottom: 50px;
}

.forecastOutlookHeader {
  h1 {
    color: $product-secondary;
  }
}

.headerWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.headerWrapForecastContent {
  justify-content: flex-end;
  margin-bottom: 0;
  width: 100%;
}

.seeAllButton {
  margin-top: 16px;
  width: 100%
}
