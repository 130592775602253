@import '../../styles/variables';

$subregion-filters-height: 32px;

.section {
  margin: 0 auto;
  max-width: 1500px;
  padding: 0 15px;

  @media (min-width: $desktopSm) {
    padding: 0 80px;
  }
}

.ad {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  width: 100%;
}

.bottomAd {
  padding-top: 50px;
}

.contentCardHeader {
  margin: 20px 0 0;

  @media (min-width: $tabletLg) {
    margin: 40px 0 0;
  }
}

.contentCardOutlook {
  margin: -40px 0 20px;

  @media (min-width: $tabletLg) {
    margin: -40px 0 40px;
  }
}

.hideOverflow {
  overflow-x: hidden;
}

.favoritesCarouselSnap {
  @media (min-width: $tablet) {
    margin-top: -55px; // needs negative margin to align with subregion filters
  }
}

$actions-width: 95px;
$actions-width-large: 250px;

.regionFiltersWrapper {
  height: $subregion-filters-height;
  position: relative;
  width: calc(100% - $actions-width);

  @media (min-width: $tablet) {
    width: calc(100% - $actions-width-large);
  }

  &::after {
    background-color: $grayLight;
    content: '';
    display: inline-block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
  }
}

.regionalFiltersActions {
  width: $actions-width;

  @media (min-width: $tablet) {
    width: $actions-width-large;
  }
}

.viewAllButton {
  width: 100%;

  @media (min-width: $tablet) {
    width: 140px;
  }
}
