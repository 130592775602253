@import '../../styles/variables';

.favoritesCarousel {
  margin: 0;
  padding: 30px 0 40px;

  @media (min-width: $tablet) {
    padding: 47px 0 55px;
  }
}

.favoritesCarouselItem {
  min-height: 0;
}
