@import '../../styles/variables';

.carouselSection {
  width: 100%;
}

.carouselWrap {
  box-sizing: border-box;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 30px 0;
  padding-bottom: 40px;
  padding-top: 25px;

  @media (min-width: $desktop) {
    padding-bottom: 60px;
    padding-top: 40px;
  }

  /* MUI based Normalization for carousel typography styles */
  h2,
  h3,
  h4,
  h5,
  p {
    line-height: 1.2em;
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 16px;
  }

  * {
    box-sizing: border-box;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 8px;
  padding: 0;
  width: 100%;

  h2,
  h5 {
    color: var(--sl-header-title-text-color) !important;
    line-height: 1.2em !important;
  }
  p {
    color: var(--sl-header-subtitle-text-color) !important;
    line-height: 1.2em !important;
  }
}

.headerLegacyPadding {
  max-width: 1600px;

  padding: 0 20px;

  @media (min-width: $tablet) {
    padding: 0 40px;
  }
}

.headerInner {
  margin: auto 0;

  @media (min-width: $tablet) {
    padding-right: 20px;
  }
}

.headerInnerProductCarousel {
  max-width: 60%;
}

.titleWrap {
  display: flex;
  margin-bottom: 10px;
  svg {
    min-width: 30px;
  }
}

.title {
  display: block;
  margin: 0 !important;
}

.subtitle {
  display: block;
  margin: 0;
  padding: 0;
}

.subtitleLink {
  color: var(--sl-header-text-color);
  display: block;
  font-family: $product-font-linear;
  font-size: 14px;
  font-weight: 700;
  margin: 10px 0;
  &:hover {
    color: $product-primary !important;
    text-decoration: none !important;
  }
}

.typeWrap {
  margin: auto 0;
}

.pageCount {
  display: inline;
  margin: auto;
  padding-right: 20px !important;
}

.mobileHeaderButtons .headerButtons {
  align-items: center;
  display: inline-flex;
  justify-content: right;
  min-width: 210px;
  text-align: right;

  .Mui-disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.headerButton {
  padding-right: 0;

  circle {
    stroke: var(--sl-header-button-color);
  }

  path {
    fill: var(--sl-header-button-color);
  }
}

.headerButton:disabled {
  opacity: 0.3;
}

.callToActionButton {
  display: inline-flex;
}

.callToActionButtonMobile {
  padding: 0;
  text-align: center;
}

.callToActionButtonWithIcon {
  padding: 12px;
  svg {
    display: none;
  }
  @media (min-width: $tablet) {
    box-shadow: 0 0 0 1px $product-secondary;
    padding: 12px 24px;
    svg {
      display: inline-block;
      width: 18px;
      padding-right: 5px;
      path {
        fill: $product-secondary;
      }
    }
  }
}

.carousel {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.carouselScrollBehaviour {
  transition: -ms-transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s, transform 0.5s ease 0s;
  white-space: nowrap;

  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scrollbar-width: none; /* Firefox */
  scroll-padding-left: 30px;

  -ms-overflow-style: none; /* IE and Edge */
  -webkit-scroll-snap-type: x mandatory;
  -webkit-scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
}

.carouselLegacyPadding {
  max-width: 1600px;

  @media (min-width: $tablet) {
    padding: 0px 40px 0px 40px;
  }
}

.carouselInner {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  overflow: visible;
}

.carouselInnerLegacyPadding {
  max-width: 1600px;
}

.carouselMobileWrap {
  box-sizing: border-box;
  margin: 0;
  padding: 0 1px;
  position: relative;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.carouselButton {
  [disabled] {
    opacity: 0.5;
  }
}

.carouselContent {
  margin: 0 -8px;
  overflow: visible;
}

.carouselContentLegacyMargin {
  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    width: 34px;
  }

  &::after {
    content: '';
    display: inline-block;
    height: 100%;
    width: 40px;
  }

  @media (min-width: $tablet) {
    &::before,
    &::after {
      content: '';
      height: 100%;
      width: 0;
    }
  }
}

.carouselItem {
  display: inline-block;
  margin: calc(var(--sl-card-gutter) / 2);
  position: relative;
  padding-left: calc(var(--sl-card-gutter) / 2);
  padding-right: calc(var(--sl-card-gutter) / 2);
  vertical-align: top;
  white-space: normal;
  min-height: 420px;
  min-width: var(--carousel-item-min-width);


  &:last-child {
    margin-right: var(--sl-section-container-gutter);
  }

  @media (min-width: $tablet) {
    width: var(--sl-card-width-tablet);

    &:last-child {
      margin-right: calc(var(--sl-card-gutter) / 2);
    }
  }

  @media (min-width: $desktop) {
    width: var(--sl-card-width-desktop);
  }

  @media (min-width: $desktopLg) {
    width: var(--sl-card-width-desktopLarge);
  }

  &::before {
    content: '';
    display: block;
    padding-top: var(--sl-card-aspect-ratio);
    width: 100%;
  }

  > .carouselItemInner {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.noAspectRatio {
  .carouselContent {
    margin-left: 0px;
    display: grid;
    grid-template-columns: repeat(var(--sl-cards-total-count), var(--sl-card-width-mobileSmall));
    gap: var(--sl-card-gutter);

    @media (min-width: $small-mobile-width) {
      grid-template-columns: repeat(var(--sl-cards-total-count), var(--sl-card-width-mobileMedium));
    }

    @media (min-width: $x-large-mobile) {
      grid-template-columns: repeat(var(--sl-cards-total-count), var(--sl-card-width-mobileLarge));
    }

    @media (min-width: $tablet) {
      grid-template-columns: repeat(var(--sl-cards-total-count), var(--sl-card-width-tablet));
    }

    @media (min-width: $desktop) {
      grid-template-columns: repeat(var(--sl-cards-total-count), var(--sl-card-width-desktop));
    }

    @media (min-width: $desktopLg) {
      grid-template-columns: repeat(var(--sl-cards-total-count), var(--sl-card-width-desktopLarge));
    }
  }

  .carouselScrollBehaviour {
    scroll-padding-left: 0px;
  }


  .carouselItem {
    margin-left: 0;
    margin-right: 0;
    min-height: 0;
    min-width: 0;
    padding: 0;
    width: 100%;

    &::before {
      padding: 0;
    }

    &:last-child {
      padding-right: var(--sl-section-container-gutter);
    }

    @media (min-width: $tablet) {
      &:last-child {
        padding-right: 0;
      }
    }

    > .carouselItemInner {
      bottom: initial;
      height: 100%;
      left: initial;
      position: relative;
      right: initial;
      top: initial;
    }
  }
}

.carouselItemProduct {
  max-width: 300px;
  min-height: 550px;

  h3 {
    padding: 0 !important;
  }

  @media (min-width: $tablet) {
    max-width: 250px;
  }

  @media (min-width: $small-desktop) {
    max-width: 260px;
  }

  @media (min-width: $tabletLg) {
    max-width: 280px;
  }

  @media (min-width: $desktopSm) {
    max-width: 250px;
  }

  @media (min-width: $desktop) {
    max-width: 280px;
  }

  @media (min-width: $desktop-large-width) {
    max-width: 300px;
  }

  @media (min-width: $desktopMd) {
    max-width: 340px;
    min-height: 600px;
  }

  @media (min-width: $desktop-xxl-width) {
    max-width: 380px;
    min-height: 650px;
  }
}

.skeleton {
  border-radius: 4px;
  background-color: var(--sl-skeleton-bg-color);
  height: 100%;
  width: 100%;
  padding: 8px;
}

.skeletonItem {
  background-color: var(--sl-skeleton-item-color);
}

.skeletonItemBig {
  height: 80%;
  background-color: var(--sl-skeleton-item-color);
}

.errorWrapper {
  min-height: 200px;
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}

.errorMessage {
  background: none;
}

.headerCta {
  text-decoration: none !important;
  button {
    box-shadow: 0 0 0 1px var(--sl-header-button-color);
    margin-right: 20px;
    width: 150px;
  }
}
.noItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 40px);
  margin: 20px;
  max-width: 1600px;
  padding: 90px 20px;
  border-radius: 8px;
  text-align: center;
  background: rgba(66, 66, 66, 0.5);
  a {
    margin-top: 30px;
    width: 150px;
    &:hover {
      color: $product-secondary !important;
      text-decoration: none !important;
    }
  }
  h3 {
    max-width: 600px;
  }
  @media (min-width: $tablet) {
    margin: 20px auto;
    padding: 60px 20px;
    width: calc(100% - 80px);
    a {
      margin-top: 20px;
    }
  }
}
