@import '../../styles/variables';

.anonHeroWrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow: hidden;
  padding-top: 40px;
  position: relative;
  width: 100%;
}

.anonHeroContent {
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (min-width: $tablet) {
    flex-direction: row;
  }
}

.anonHeaderLogo {
  display: block;

  @media (min-width: $tablet) {
    display: none;
  }

  @media (min-width: 1280px) {
    display: block;
  }
}

.anonHeroVideoSection {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.anonHeroVideoWrapper {
  aspect-ratio: 16/9;
  background: $product-secondary;
  border-radius: 16px;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (min-width: $tablet) {
    margin-bottom: 0;
  }
}

.anonHeaderSection {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin: 0;
  z-index: 1;

  @media (min-width: $tablet) {
    margin: auto auto auto 30px;
  }

  @media (min-width: $desktop) {
    margin: auto auto auto 55px;
  }
}

.anonHeaderHeadline {
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 25px;
  max-width: 270px;

  @media (min-width: $large-mobile) {
    font-size: 42px;
    line-height: 48px;
    max-width: unset;
  }

  @media (min-width: $desktop) {
    font-size: 60px;
    line-height: 72px;
    max-width: 500px;
  }
}

.anonHeaderButtonWrapper {
  display: flex;
}

.anonHeroMobileDivider {
  border: 0.5px solid $product-light;
  display: block;
  height: 0;
  margin: 40px 0 0 20px;
  width: 100%;

  @media (min-width: $large-mobile) {
    margin: 40px 0 0 30px;
  }

  @media (min-width: $tablet) {
    display: none;
  }
}

.anonHeroCarouselWrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  z-index: 1;

  :global {
    .MuiTypography-h2 {
      max-width: 450px;
    }

    .header-buttons-wrapper {
      min-width: 252px;
    }

    @media (min-width: $tablet) {
      .MuiTypography-h2 {
        max-width: unset;
      }

      .carouselCallToActionButton--dark {
        box-shadow: 0 0 0 1px $product-light;

        &:hover {
          box-shadow: 0 0 0 1.5px $product-light;
        }
      }

      .carouselCallToActionButton--light {
        box-shadow: 0 0 0 1px $product-secondary;

        &:hover {
          box-shadow: 0 0 0 1.5px $product-secondary;
        }
      }
    }
  }
}

.anonHeroSubheadline {
  margin-bottom: 25px;
  max-width: 250px;

  @media (min-width: $small-mobile-width) {
    max-width: unset;
  }
}

.anonHeroBackgroundSVG {
  position: absolute;
  right: -100px;
  top: -480px;
  z-index: 0;

  @media (min-width: $tablet) {
    left: 0;
    top: 0;
    right: unset;
  }
}

.desktopSVG {
  display: none;

  @media (min-width: $tablet) {
    display: block;
  }
}

.mobileSVG {
  display: block;

  @media (min-width: $tablet) {
    display: none;
  }
}
