// non-quiver colors
$gray-blue-dark: #1f2532;
$gray-blue: #96a9b2;
$light-gray-blue: #dde4e6;
$swell-purple: #9100ff;

// 2022 redesign colors
// TODO: stop using values; deprecate and use values in @wavetrak/theme/global.scss
$product-primary: #314ee6;
$product-secondary: #171717;
$product-base: #ffffff;
$product-body: #424242;
$product-body-secondary: #98a2af;
$product-light: #e4e7ed;
$product-extra-light: #fafafa;
$product-callout: #EC2B2B;
$forecast-primary: #b9d3df;
$paywall-background: #f4f1e8;
$inactive-color: #BBBBBB;
$accent-primary: #272727;
$insights-unridden-inactive: #6F7F86;
$insights-ridden-inactive: #89982C;
$brand-expressive-bronzing: #D0A847;

$insights-disabled: #373F43;

// Swell Height colors
$swell-height-colors-ft: (
  1: (
    background-color: #003fff,
    color: color('white'),
  ),
  2: (
    background-color: #0066ff,
    color: color('white'),
  ),
  3: (
    background-color: #00b2ff,
    color: color('gray', 90),
  ),
  4: (
    background-color: #00ffff,
    color: color('gray', 90),
  ),
  5: (
    background-color: #7fffbf,
    color: color('gray', 90),
  ),
  6: (
    background-color: #00ff4c,
    color: color('gray', 90),
  ),
  7: (
    background-color: #00cc66,
    color: color('gray', 90),
  ),
  8: (
    background-color: #a5e566,
    color: color('gray', 90),
  ),
  9: (
    background-color: #ffff59,
    color: color('gray', 90),
  ),
  10: (
    background-color: #ffe519,
    color: color('gray', 90),
  ),
  12: (
    background-color: #ffb200,
    color: color('gray', 90),
  ),
  14: (
    background-color: #ff7f00,
    color: color('gray', 90),
  ),
  16: (
    background-color: #ff3d00,
    color: color('gray', 90),
  ),
  18: (
    background-color: #ff0000,
    color: color('white'),
  ),
  20: (
    background-color: #c10000,
    color: color('white'),
  ),
  23: (
    background-color: #800015,
    color: color('white'),
  ),
  26: (
    background-color: #a0004c,
    color: color('white'),
  ),
  29: (
    background-color: #c10099,
    color: color('white'),
  ),
  32: (
    background-color: #df00ff,
    color: color('gray', 90),
  ),
  35: (
    background-color: #ff00ff,
    color: color('gray', 90),
  ),
);

$swell-height-colors-m: (
  0_33: map-get($swell-height-colors-ft, 1),
  0_67: map-get($swell-height-colors-ft, 2),
  1: map-get($swell-height-colors-ft, 3),
  1_33: map-get($swell-height-colors-ft, 4),
  1_67: map-get($swell-height-colors-ft, 5),
  2: map-get($swell-height-colors-ft, 6),
  2_33: map-get($swell-height-colors-ft, 7),
  2_67: map-get($swell-height-colors-ft, 8),
  3: map-get($swell-height-colors-ft, 9),
  3_5: map-get($swell-height-colors-ft, 10),
  4: map-get($swell-height-colors-ft, 12),
  4_5: map-get($swell-height-colors-ft, 14),
  5: map-get($swell-height-colors-ft, 16),
  5_5: map-get($swell-height-colors-ft, 18),
  6: map-get($swell-height-colors-ft, 20),
  7: map-get($swell-height-colors-ft, 23),
  8: map-get($swell-height-colors-ft, 26),
  9: map-get($swell-height-colors-ft, 29),
  10: map-get($swell-height-colors-ft, 32),
  11: map-get($swell-height-colors-ft, 35),
);

// from package @surfline/quiver-themes
@function color($palette, $tone: 'core') {
  @return map-get(map-get($__sl-color-palettes, $palette), $tone);
}

$good-score-color: #fff7cc;
$optimal-score-color: #ffe866;

$swell-colors: (
  0: #0058b0,
  1: #c358ed,
  2: #ff8309,
  3: #0197f6,
  4: #333333,
  5: #96a9b2,
);

$condition-colors: (
  veryPoor: #98a2af,
  poor: #408fff,
  poorToFair: #30d2e8,
  fair: #1ad64c,
  fairToGood: #ffcd1e,
  good: #ff8900,
  epic: #d54530,
  none: #4d8ba7,
);

$__sl-color-palettes: (
  // Surfline
  'light-blue': (
    core: #42A5FC,
    5: #F2F7F9,
    10: #E6F3FF,
    20: #B5DBFC,
    30: #8CC6F9,
    40: #66B5FA,
    50: #42A5FC, // SL Secondary
    60: #3193EC,
    70: #2283DD,
    80: #1171CE,
    90: #0261BF
  ),

  'dark-blue': (
    core: #003366,
    10: #0058B0,
    20: #004D99,
    30: #004488,
    40: #003C78,
    50: #003366, // SL Primary
    60: #002E5C,
    70: #012850,
    80: #012345,
    90: #011D38
  ),

  // FishTrack
  'aqua': (
    core: #67D3DF,
    10: #D5F3F7,
    20: #BAEBF1,
    30: #9DE3EA,
    40: #82DBE5,
    50: #67D3DF,
    60: #5AC1CC,
    70: #4AA9B4,
    80: #3B939D,
    90: #2D7E87
  ),

  'shark-blue': (
    core: #385C6C,
    10: #ADC4CD,
    20: #92ABB7,
    30: #728F9C,
    40: #567785,
    50: #385C6C,
    60: #305160,
    70: #284754,
    80: #203C49,
    90: #18313D
  ),

  // Buoyweather
  'marine-blue': (
    core: #002447,
    10: #D7F0FA,
    20: #9FDAF3,
    30: #6BC5EC,
    40: #48B7E6,
    50: #27AAE1,
    60: #209CD0,
    70: #198DBF,
    80: #117FAD,
    90: #09709B
  ),

  'nautical-blue': (
    core: #3B698F,
    10: #A4C2DA,
    20: #8BACC8,
    30: #6D94B3,
    40: #557FA1,
    50: #3B698F,
    60: #2F597C,
    70: #254B6B,
    80: #183A56,
    90: #0D2B44
  ),

  // Shared
  'green': (
    core: #23D737,
    10: #D9FBDD,
    20: #A4F2AD,
    30: #62E270,
    40: #43DD54,
    50: #23D737,
    60: #1EB92F,
    70: #199927,
    80: #147B20,
    90: #115D19
  ),

  'orange': (
    core: #FFBE00,
    10: #FFF8E5,
    20: #FDE7A4,
    30: #FCD358,
    40: #FEC92E,
    50: #FFBE00,
    60: #E9AE01,
    70: #D39E03,
    80: #BC8D03,
    90: #A77E05
  ),

  'red': (
    core: #FA5065,
    10: #FFF3F4,
    20: #FCBAC1,
    30: #F98694,
    40: #F96B7D,
    50: #FA5065,
    60: #DE485B,
    70: #C34151,
    80: #A93B48,
    90: #8E333E
  ),

  'bright-red': (
    core: #FF0000,
    30: #F5424B,
    50: #FF0000,
    60: #DD0000,
  ),

  'gray': (
    core: #A9A9A9,
    10: #FAFAFA,
    20: #F4F4F4,
    30: #E6E6E6,
    40: #CCCCCC,
    50: #A9A9A9,
    55: #96A9B2,
    60: #999999,
    70: #6E7273,
    80: #555555,
    85: #3D3D3D,
    90: #333333,
    95: #1F2532
  ),

  'blue-gray': (
    core: #B5C4CB,
    10: #F2F7F9,
    15: #E6F4FB,
    20: #E5EEF2,
    30: #D5E0E5,
    40: #C5D2D8,
    50: #B5C4CB,
    60: #A5B6BF,
    70: #96A9B2,
    80: #7A8E97,
    90: #60737C
  ),

  'white': (
    core: #FFFFFF
  ),

  'black': (
    core: #000000,
    30: #222222,
    40: #1a1a1a,
    50: #000000
  ),

  'social': (
    facebook: #3a5998,
    twitter: #4099ff
  ),

  'swell-purple': (
    core: #9100FF,
    10: #f1e1fc,
    20: #edd8fb,
    50: #9100FF
  )
);
