@import './media';
@import './colors';
@import './fonts';
@import '~@surfline/quiver-assets/productcdn';
@import '~@wavetrak/theme/global.scss';

$cdn-background-path: '#{$sl-product-cdn}/backgrounds';
$cdn-icons-path: '#{$sl-product-cdn}/icons';
$cdn-logo-path: '#{$sl-product-cdn}/logos';

$sl-overlay-background-hover: rgba(0, 0, 0, .2);
$sl-overlay-box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 4px 4px 0px rgb(0 0 0 / 14%), 0px 1px 3px 2px rgb(0 0 0 / 12%);

$zindex: (
  cam-player: 1,
  forecast-header-menu--mobile: 999,
  forecast-header-menu: 50,
  forecast-visualizer-cta: 2,
  map-control: 80,
  map-overlay: 75,
  map-tile-layer: 70,
  map: 90,
  nearby-spots-menu: 50,
  over-map: 91,
);

@function zindex($label) {
  @return map-get($zindex, $label);
}

$graph-height: (
  advancedSwell: 260px,
  consistency: 90px,
  energy: 200px,
  lineup: 168px,
  tide: 100px,
  timeline: 26px,
  tooltipArea: 45px,
);

@function graphHeight($label) {
  @return map-get($graph-height, $label);
}

// header
$favorites-bar-height: 50px;

// WARNING: If you change these make sure to update them in the JS file as well
$small_map_card_height: 130px;
$small_map_card_width: 203px;
$large_map_card_height: 184px;
$large_only_map_card_height: 211px;
$large_map_card_width: 300px;
$large_only_map_card_width: 296px;

$header-height: 97px;

$site-header-height-mobile: 96px;
$site-header-height-tablet: 146px;
$site-header-height-desktop: 106px;
$site-header-favorites-bar-height: 52px;
$site-header-taxonomy-navigator-height: 634px;
$site-header-taxonomy-navigator-width: 785px;

$site-footer-height-mobile: 812px;
$site-footer-height-tablet: 502px;
$site-footer-height-medium: 562px;
$site-footer-height-desktop: 324px;

$spot-page-header-height: 126px;
$spot-page-header-height-tablet: 130px;

$maptiler-menu-margin-left: 30px;

// Used in geonamemap and map page spot lits
$map-page-spot-carousel-height: 149px;
$map-controls-margin: 0 0 20px 20px;

// from quiver-themes
$navigation-bar-height: 56px;
$mobile-navigation-bar-height: 40px;

$right-rail-width: 320px;
