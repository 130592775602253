@import 'styles/_variables.scss';

.wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  margin: auto 6px auto 14px;
  position: relative;
  width: 12px;

  @media (min-width: $small-mobile-width) {
    justify-content: center;
    margin: auto;
    width: 26px;
  }
}

.textWrapper {
  color: $product-light;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  margin: auto;
  min-width: 110px;
  position: relative;
}

.ratingText {
  margin: auto;
}

.bar {
  border-radius: 6px;
  height: 44px;
  width: 6px;

  @media (min-width: $medium-mobile) {
    height: 46px;
  }
}

.emptyRatingsBar {
  height: 34px;
  border-right: 1px solid $product-light;
  width: 0;

  @media (min-width: $medium-mobile) {
    height: 46px;
  }
}

.text {
  display: flex;
  font-weight: 800;
}
