.pageContainer {
  height: auto;
  background-color: black;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 20px 0px;
  }
}

.regionalHeaderWrapper {
  display: flex;
  justify-content: flex-start;
  margin-left: calc(max(80px, (100% - ((100vh - 300px) * 16/9)) / 2));
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    margin-left: calc(max(30px, (100% - ((100vh - 300px) * 16/9)) / 2));
    font-size: 14px;
  }
}

.seeMoreButton {
  padding: 0;
  margin-left: 15px;
  color: white;
  height: auto;
  align-self: flex-start;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: white;
    text-decoration: underline;
  }

  svg {
    &:hover {
      color: white;
    }
  }
}

.regionalHeader {
  color: white;
  font-size: 24px;
  font-weight: 700;
  width: 100%;
  margin-left: 20px;
}

.accordion {
  background-color: black;
}

.accordionDetails {
  padding: 0;
}

.expandIcon {
  color: white;
}