@import '../../styles/variables';

.forecasterContentGrouped {
  background-color: $background;
}

.gridBorder {
  position: relative;

  &::after {
    background-color: $grayLight;
    content: '';
    position: absolute;
    top: 0;
    right: -40px;
    width: 1px;
    height: 100%;
  }
}

.cardText {
  line-height: 1.3em;
  margin: 0.3em 0 1.3em;
}

.paywallLockButton {
  background-color: $brandSecondary;
  height: 44px;
  width: 44px;

  svg {
    height: 20px;
    width: 20px;

    path {
      fill: $white;
    }
  }

  &:hover,
  &:focus-visible {
    background-color: $textPrimary;
  }
}

.paywallLockButtonUnlocked {
  svg {
    path {
      fill: $greenFlash;
    }
  }
}

.loadingGrouped {
  background-color: $background;
  border-radius: 8px;
  display: block;
  min-height: 250px;
}

.star {
  margin-bottom: -1px;
}

.forecastNotes {
  a {
    outline: none;
    text-decoration: none;

    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }
}
