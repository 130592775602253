@import '../../styles/variables';

.editorialCarouselBleed {
  background-color: black;
}

.editorialCarousel {
  margin: 0;
  padding: 30px 0 40px;

  @media (min-width: $tablet) {
    padding: 40px 0 50px;
  }

  @media (min-width: $desktop) {
    padding: 47x 0 55px;
  }
}

.editorialCardCarousel {
  min-height: 530px;
  @media (min-width: $tablet) {
    min-height: 420px;
  }
  @media (min-width: $desktop-large-width) {
    min-height: 600px;
  }
}
