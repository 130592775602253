@import '../../styles/variables';

@keyframes fadeInItems {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.scrollContainer {
  -ms-overflow-style: none; /* IE and Edge */
  -webkit-scroll-behavior: smooth;
  -webkit-scroll-snap-type: x mandatory;
  display: flex;
  list-style: none;
  margin: 0 0 0 calc(var(--sl-section-container-gutter) * -1);
  overflow: auto;
  padding: 0;
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scroll-margin-left: 100px;
  scrollbar-width: none; /* Firefox */
  width: calc(100% + calc(var(--sl-section-container-gutter) * 2));

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: $tablet) {
    margin: 0 0 0 -16px;
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0px,
      rgba(0, 0, 0, 1) 16px,
      rgba(0, 0, 0, 1) calc(100% - 32px),
      rgba(0, 0, 0, 0) 100%
    );
    padding-right: 32px;
    width: calc(100% + 48px);
  }
}

.item {
  animation: fadeInItems 200ms ease-in-out;
  flex-shrink: 0;
  padding: 0 0 15px 16px;
  width: calc(100% - 20px);

  &:first-child {
    padding-left: 20px;
    width: calc(100% - 20px);
  }

  &:last-child {
    padding-right: 20px;
    width: 100%;
  }

  @media (min-width: $tablet) {
    width: calc(100% / 2);

    &:first-child {
      padding-left: 16px;
      width: calc(100% / 2);
    }

    &:last-child {
      padding-right: 0;
      width: calc(100% / 2);
    }
  }

  @media (min-width: $desktop) {
    width: calc(100% / 3);

    &:first-child,
    &:last-child {
      width: calc(100% / 3);
    }
  }

  @media (min-width: $desktopLg) {
    width: calc(100% / 4);

    &:first-child,
    &:last-child {
      width: calc(100% / 4);
    }
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
  display: none;

  @media (min-width: $tablet) {
    display: flex;
  }
}

.viewAllButton {
  @media (min-width: $tablet) {
    margin-right: 10px;
  }
}

.arrow {
  display: inline-flex;
  height: 44px;
  margin-top: -6px;
  padding: 6px 0;
  width: 40px;

  &:nth-child(1) {
    padding-left: 6px;
    padding-right: 2px;
  }

  &:nth-child(2) {
    padding-left: 2px;
    padding-right: 6px;
  }

  svg {
    height: 32px;
    width: 32px;

    circle {
      stroke: $grayLight;
    }

    path {
      fill: $textPrimary;
    }
  }

  &:hover,
  &:focus-visible {
    svg {
      circle {
        stroke: $textPrimary;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;

    svg {
      circle {
        stroke: $grayLight;
      }

      path {
        fill: $grayLight;
      }
    }
  }
}
