.buttonContainer {
  display: flex;
  margin: 20px 0;
  flex-direction: row;
  margin-left: calc(max(80px, (100% - ((100vh - 300px) * 16/9)) / 2));
  overflow-x: auto;
  padding: 2px;

  @media (max-width: 768px) {
    margin-left: calc(max(30px, (100% - ((100vh - 300px) * 16/9)) / 2));
  }
}

.button {
  color: white;
  margin-right: 5px;
  flex-shrink: 0;

  &:hover {
    color: white;
  }
}

.activeButton {
  background-color: rgba(255, 255, 255, 0.3);

  &:focus {
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
    border: 1px solid white;
  }
}
