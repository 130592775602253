@use 'sass:map';
@use 'sass:math';

@import './variables';

@mixin linkStyle {
  font-family: $source-sans-pro;
  font-size: 14px;
  color: color('dark-blue', 10);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

@mixin module-shadow() {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
}

@mixin sl-graph-container($mobile-width: 50%) {
  background: color('white');
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
  color: #424242;
  padding: 15px;
  width: calc($mobile-width - 6px);
  h4 {
    font-family: $product-font-linear;
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    margin: 10px 0 15px;
  }
  span {
    font-size: 18px;
    line-height: 30px;
  }
  @media (min-width: $desktopSm) {
    box-shadow: none;
    height: 100px;
    margin-bottom: 35px;
    padding: 0;
    width: 100%;
  }
}

@mixin small-paragraph {
  margin: 0;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 20px;
  color: color('gray', 70);
}

@mixin paragraph {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: color('gray', 90);
}

@mixin sl-h4 {
  margin: 0;
  padding-top: 10px;
  font-family: $product-font-linear;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 1px;
  font-weight: 400;
  color: color('gray', 90);
}

@mixin sl-h5 {
  margin: 0;
  padding-top: 10px;
  font-family: $product-font-linear;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 1.5px;
  font-weight: 400;
  color: color('gray', 90);
}

@mixin sl-h4 {
  margin: 0;
  padding-top: 10px;
  font-family: $product-font-linear;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 1px;
  font-weight: 400;
  color: color('gray', 90);
}

@mixin sl-h6 {
  margin: 0;
  font-family: $product-font-linear;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.88px;
  text-transform: uppercase;
  font-weight: 400;
  color: color('gray', 55);

  @media (min-width: $tablet) {
    font-size: 18px;
    letter-spacing: 1px;
  }
}

@mixin action-styles {
  font-family: $product-font-linear;
  text-transform: uppercase;
  color: color('gray', 90);
}

// Remove when the aspect-ration mixin is export from quiver
@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    display: block;
    content: ' ';
    width: 100%;
    padding-top: (math.div($height, $width)) * 100%;
  }
}

@mixin sl-video-play-icon {
  opacity: 0.5;
  position: absolute;
  svg {
    margin: auto;
  }
}

@mixin maptiler-menu-header-title {
  margin: 0;
  display: flex;
  align-items: center;
  color: $product-secondary
}

@mixin maptiler-menu-button() {
  width: calc(100% - $maptiler-menu-margin-left);
  height: 60px;
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-left: $maptiler-menu-margin-left;
  border-bottom: 0.5px solid $product-light;
}

@mixin maptiler-menu-button-label {
  display: flex;
  justify-content: center;
  align-items: center;
}
